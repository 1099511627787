import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row, Container, Modal, Form } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ribbonimg from "../Assets/image/ribbon.png";
import Tokyo from "../Assets/image/Tokyo.png";
import Turkey from "../Assets/image/Turkey.png";
import { GiftTransfer, fetchGifts } from "../Constant/ApiCalling";
import Pagination from "../Components/Pagination";
import Loader from "./Loader";
import { NotificationTypes, showNotification } from "../Constant/Alert";
import { emailRegex, instance } from "../Constant/Constant";
import { PulseLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faCreditCard,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
function SendGiftCard() {
  const [show, setShow] = useState(false);
  const [gifts, setGifts] = useState([]);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(null); // Add state to hold the previous page number
  const [totalItems, setTotalItems] = useState(0);
  const [itemToTransferId, setItemToTransferId] = useState(null);
  const [updateIndex, setUpdateIndex] = useState(null);
  const [error, setError] = useState(false);
  const itemsPerPage = 6;
  const [loading, setLoading] = useState({
    fetchGifts: false,
    giftTransfer: false,
  });

  const [email, setEmail] = useState("");

  const navigate = useNavigate();
  const inputRef = useRef(null);

  const getAllGifts = async (page) => {
    try {
      setLoading((prevLoading) => ({ ...prevLoading, fetchGifts: true }));
      const response = await fetchGifts(itemsPerPage, page);
      setGifts(response?.data);

      if (!response?.data?.length) {
        getAllGifts(currentPage - 1);
        setCurrentPage(currentPage - 1);
      }

      setTotalItems(response?.totalItems);
      setError(false);
    } catch (error) {
      showNotification("Error fetching gifts:", NotificationTypes.ERROR);
      setError(true);
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, fetchGifts: false }));
    }
  };

  const handleClose = () => {
    setShow(false);
    setEmail("");
    setIsValidEmail(true);
  };

  const handlePageChange = (page) => {
    setPreviousPage(currentPage);
    setCurrentPage(page);
    getAllGifts(page);
  };

  // useEffect(() => {
  //   getAllGifts(1);
  // }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const trimmedValue = value.trim();
    setEmail(trimmedValue);

    if (name === "email") {
      setIsValidEmail(emailRegex.test(trimmedValue));
    }
  };

  const handleShow = (index) => {
    const clickedGift = gifts[index];

    if (clickedGift && clickedGift?.id) {
      setItemToTransferId(clickedGift?.id);
      setUpdateIndex(index);
    }

    setShow(true);
  };

  const handleTransferClick = async (e) => {
    e.preventDefault();

    if (!email) {
      showNotification("Email is required ", NotificationTypes.ERROR);
      return;
    }

    if (!isValidEmail) {
      return;
    }
    const giftId = itemToTransferId;

    try {
      setLoading((prevLoading) => ({ ...prevLoading, giftTransfer: true }));

      await GiftTransfer(giftId, email);
      const updatedGifts = [...gifts];
      updatedGifts.splice(updateIndex, 1);

      setGifts(updatedGifts);
      showNotification(
        "Gift successfully Transferred",
        NotificationTypes.SUCCESS
      );
      setEmail("");
      setShow(false);

      getAllGifts(currentPage);
    } catch (error) {
      showNotification(
        error?.response?.data?.error?.errorMessage,
        NotificationTypes.ERROR
      );
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, giftTransfer: false }));
    }
  };

  useEffect(() => {
    if (show) {
      inputRef.current.focus();
    }
  }, [show]);

  return (
    <>
      <div className='comming-soon-pnl'>
            <h1>Comming soon</h1>
          </div>
      {/* <main className="inner-main">
        <Container fluid>
          <Row>
            <Container>
              <Row>
                {loading?.fetchGifts ? (
                  <Loader animation="border" />
                ) : error ? (
                  <Col xl="12" lg="12" md="12">
                    <div className="text-center mt-10">
                      <span>Failed to load gifts. Please try again.</span>
                    </div>
                  </Col>
                ) : (
                  <>
                    {gifts?.length ? (
                      gifts.map((gift, index) => (
                        <Col key={gift?.id} xl="4" lg="4" md="6">
                          <div className="gift-card-cntnr">
                            <div className="gift-card active">
                              <div
                                className="bg-layer"
                                style={{ backgroundImage: `url(${ribbonimg})` }}
                              ></div>
                              <img src={Turkey} alt="Turkey" />
                              <h6>
                                <b>Gift</b>Card
                              </h6>
                              <div className="text-left">
                                <h5>
                                  <b>
                                    {gift?.price !== 0
                                      ? parseFloat(gift.price)
                                          .toFixed(4)
                                          .replace(/\.?0*$/, "") + " CAD"
                                      : null}
                                  </b>
                                </h5>
                              </div>
                            </div>
                            <div className="text-center">
                              <Button
                                className="send-btn"
                                onClick={() => handleShow(index)}
                              >
                                Send
                              </Button>
                            </div>
                          </div>
                        </Col>
                      ))
                    ) : (
                      <Col xl="12" lg="12" md="12">
                        <div className="text-center"></div>
                      </Col>
                    )}
                  </>
                )}
                {gifts?.length > 0 && (
                  <Col xl="12" lg="12" md="12">
                    <div className="text-center">
                      <div className="pag">
                        <Pagination
                          currentPage={currentPage}
                          itemsPerPage={itemsPerPage}
                          totalItems={totalItems}
                          onPageChange={(page) => handlePageChange(page)}
                        />
                      </div>

                      <div className="spacer-20"></div>
                    </div>
                  </Col>
                )}
              </Row>
            </Container>
          </Row>
        </Container>
      </main> */}
      <Modal show={show} centered onHide={handleClose}>
        <Button className="close-btn" onClick={handleClose}>
          <FontAwesomeIcon icon={faClose} />
        </Button>
        <Modal.Header>
          <Modal.Title>Send Gift Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="send-gift-pnl">
              <Form.Label>Enter Email</Form.Label>

              <Form.Control
                type="text"
                ref={inputRef}
                name="email"
                autoComplete="off"
                value={email}
                onChange={handleInputChange}
                isInvalid={!isValidEmail && email.trim() !== ""}
                placeholder="name@example.com"
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid email address
              </Form.Control.Feedback>
            </div>

            <Button
              onClick={handleTransferClick}
              disabled={loading?.giftTransfer}
              type="submit"
              className="purple-btn"
            >
              {loading?.giftTransfer ? (
                <PulseLoader size={10} color="#fff" />
              ) : (
                "Send"
              )}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default SendGiftCard;
