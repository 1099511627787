import React, { useEffect, useState } from 'react';
import { SwapWidget } from '@uniswap/widgets';
import '@uniswap/widgets/fonts.css';
import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import { JSON_RPC_URL, polygonTokens, theme } from '../Constant/Constant';
import { useAddress, useNetworkMismatch, useNetwork, ChainId } from '@thirdweb-dev/react';

function UniSwap() {
  const [providerMetaMask, setProviderMetaMask] = useState(null);
//   const [polygonTokens, setPolygonTokens] = useState([]);
  const [loading, setLoading] = useState(true);
  const address = useAddress();
  const isWrongBlockchain = useNetworkMismatch();
  const [, switchNetwork] = useNetwork();

  // Detect and setup MetaMask provider
  const setupProvider = async () => {
    const provider = await detectEthereumProvider();
    if (provider) {
      setProviderMetaMask(provider);
      window.web3 = new Web3(provider);
    }
  };



  useEffect(() => {
    setupProvider();
  }, []);

  return (
    <main className='inner-main'>
      <div className='full-div'>
        <div className='card-panel'>
            <SwapWidget
              provider={providerMetaMask}
              theme={theme}
              jsonRpcUrlMap={{ [ChainId.Polygon]: JSON_RPC_URL }}
              tokenList={polygonTokens} // **Pass manually defined tokens**
              className='uniswap'
            />
    
        </div>
      </div>
    </main>
  );
}

export default UniSwap;
