import { ChainId, IpfsStorage, ThirdwebProvider } from "@thirdweb-dev/react";
import Layout from './Layout';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { RedlightChain } from "@thirdweb-dev/chains";
import { customChain } from "./Constant/Constant";

function App() {
  return <>
  
  <ThirdwebProvider
        //  activeChain="binance-testnet"

      //  activeChain="polygon"
      activeChain={customChain}
      
      // dAppMeta={{
      //   url: "https://example.com",
      // }}
      // supportedWallets={[
      //   metamaskWallet({
      //     recommended: true,
      //   }),
      //   coinbaseWallet(),
      //   walletConnect(),
      //   trustWallet({
      //     recommended: true,
      //   }),
      // ]}

    >
     <Layout />
    </ThirdwebProvider>
 
  <ToastContainer
position="bottom-center"
autoClose={2000}
hideProgressBar={true}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/>
  </>
}
export default App;