import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import leaf from '../Assets/image/leaf.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { emailRegex, instance, passwordRegex } from '../Constant/Constant';
import { PulseLoader } from 'react-spinners';
import { useContextDetails } from '../Constant/AppContext';
function Login({ setShowNavBar }) {
    // Hide the footer when the Login component mounts
    useEffect(() => {
        setShowNavBar(false);
        return () => {
            setShowNavBar(true);
        };
    }, [setShowNavBar]);
    const contextData = useContextDetails();
  

    const [loginData, setLoginData] = useState({
        userInfo: "",
        password: "",
    });

    const navigate = useNavigate();
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({
        email: "",
        password: "",
        errormsg: "",
        apiresponeerror:"",
    });
 
   
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const trimmedValue = name === "userInfo" ? value.trim() : value;

        // if (name === "password") {
        //   if (trimmedValue.length > 16) {
        //     setErrors((prevErrors) => ({
        //       ...prevErrors,
        //       password: "Password cannot be longer than 16 characters.",
        //     }));
        //     return;
        //   }
        // }
        setLoginData({
            ...loginData,
            [name]: trimmedValue,
        });

        if (name === "userInfo") {
            setIsValidEmail(emailRegex.test(trimmedValue));
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: emailRegex.test(trimmedValue) ? "" : "Please enter a valid email address",
                errormsg:"",
                apiresponseerror:""
            }))
        } else if (name === "password") {
            const passwordValid = (trimmedValue);
            setIsPasswordValid(passwordValid);
    setErrors((prevErrors) => ({
      ...prevErrors,
      password: passwordValid
        ? ""
        : "Please enter the password ",
        errormsg:"",
        apiresponeerror:""
    }));
        }
    };

    const handleLoginClick = (e) => {
        e.preventDefault();

        if (!loginData.userInfo || !loginData.password) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                errormsg: "Please fill in all required fields",
            }));
            return;
        }
        // if (!loginData.userInfo || !loginData.password) {
        //   setErrorMessage("Please fill in all required fields");
        // } else {
        //   setErrorMessage("");    
        // }

        if (!isValidEmail) {
            // showNotification("Invalid email address", NotificationTypes.ERROR);
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: "Please enter a valid email address" || "",
            }));
            return;
        }
        // if (!isPasswordValid) {
        //   setErrors((prevErrors) => ({
        //     ...prevErrors,
        //     password:
        //       "Please enter a password that is at least 8 characters long and contains at least one uppercase letter, one lowercase letter, one numeric character, and one special character." ||
        //       "",
        //   }));
        //   return;
        // }
        setIsLoading(true);


        const requestData = {
            email: loginData?.userInfo,
            password: loginData?.password,
        };

        instance
        .post("/api/Admin/Login", requestData)
        .then((response) => {
            const accessToken = response?.data?.data?.accessToken;
            if (!accessToken) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    apiresponeerror: "Incorrect username or password not found",
                }));
                return;
            }

            sessionStorage.setItem("JsonWebToken", accessToken);

            contextData?.setuserInfo(response?.data?.data?.userInfo);
            sessionStorage.setItem("userdetail", JSON.stringify(response?.data?.data?.userInfo));

            navigate("/CoinSwap");
        })
        .catch((message) => {
            setErrors((prevErrors) => ({
                ...prevErrors,
                apiresponeerror: message?.response?.data?.message || "",
            }));
        })
        .finally(() => {
            setIsLoading(false);
        });
};
useEffect(() => {
    const accessToken = sessionStorage.getItem("JsonWebToken");
    if (accessToken) {
        navigate("/CoinSwap");
    }
}, []);
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
   

    
    return (
        <>
            <main className='log-main'>
                <div className='intro bg-gradient'>
                    <div className='bg-layer'></div>
                    <div className='intro-inner'>
                        <img src={leaf} alt="Leaf" />
                        <h1>1CM Inc</h1>
                        <h4>
                            A multidimensional cannabis company focused on it’s retail customers and technology to democratize cannabis markets.
                        </h4>
                    </div>
                </div>
                <div className='welcome-pnl'>
                    <div className='welcome-inner'>
                        <h2>Welcome Back</h2>
                        <h4>Login To Continue</h4>
                        <div className='spacer-20'></div>
                        <Form onSubmit={handleLoginClick}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Control type="text"
                                    name="userInfo"

                                    value={loginData.userInfo}
                                    onChange={handleInputChange}
                                    isInvalid={!isValidEmail} placeholder="Email" />
                                   <Form.Control.Feedback type="invalid">Please enter a valid email address</Form.Control.Feedback>

                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <div className='position-relative'>
                                    <Form.Control type={showPassword ? 'text' : 'password'} name='password'
                                        placeholder='Password' value={loginData.password} onChange={handleInputChange} />
                                    <div className='icon-cntnr' onClick={togglePasswordVisibility}>
                                        {/* <FontAwesomeIcon icon={faEye} /> */}
                                        {/* <FontAwesomeIcon icon={faEyeSlash} /> */}
                                        {showPassword ? (
                                            <FontAwesomeIcon icon={faEye} />
                                        ) : (
                                            <FontAwesomeIcon icon={faEyeSlash} />
                                        )}

                                    </div>
                                </div>
                            </Form.Group>
                            {errors.errormsg && (
                            <span className="errormsg " >
                    {errors.errormsg}
                  </span>
                            )}
                  <span className="errormsg " >
                    {errors.apiresponeerror}
                  </span>
                            <div className='text-right mb-3'>
                                {/* <Link to="/ForgotPassword">Forgot password</Link> */}
                                <div className='spacer-20'></div>
                            </div>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                {/* <Form.Control className="Submit-btn" type="submit" value="Login" /> */}
                                {/* <Button className="Submit-btn">Save</Button> */}
                                <Button
                                    disabled={isLoading}
                                    type="submit"
                                    className="Submit-btn" >
                                    {isLoading ? <PulseLoader size={10} color="#fff" /> : 'Login'}
                                </Button>
                            </Form.Group>
                        </Form>
                    </div>
                </div>
            </main>
        </>
    );
}
export default Login; 