import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Modal, Col, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import card from '../Assets/image/card.png';
import coin1 from '../Assets/image/coin-2.png';
import { useContextDetails } from '../Constant/AppContext';
import { PulseLoader } from 'react-spinners';
import { NotificationTypes, showNotification } from '../Constant/Alert';
import setAuthorizationToken, { cardTypeImages, clientId, clientSecret, instance, masterwallect, tokenContractAddress } from '../Constant/Constant';

import coin from '../Assets/image/coin-1.png';
import Web3 from 'web3';
import { tokenContractAbi } from '../Web3/TokenContractAbi';
import { GetBuyGiftsBankCard, GetSaleToken, GetToken, GetTransationGenrate, Login, fetchExchangeRate, fetchWalletBalance, postGiftPurchase, postOnlineGiftPurchase } from '../Constant/ApiCalling';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faCreditCard, faXmark } from '@fortawesome/free-solid-svg-icons';
import creditCardType from 'credit-card-type';
import { ChainIds, formatAmount, getTokenBalance } from '../Web3/Constant';
import axios from 'axios';
import { useAccount } from 'wagmi';
import { useAddress, useChainId, useContract, useTransferToken } from '@thirdweb-dev/react';
import { useContractWrite } from "@thirdweb-dev/react";

function PurchaseGift() {
  const [selectedMethod, setSelectedMethod] = useState('Offline Coin');
  const [walletAddress, setWalletAddress] = useState(masterwallect);

  const [amount, setAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [isSwap, setIsSwap] = useState(false);
  const [txHash, setTxHash] = useState(null);
  const [showCardDetailsModal, setShowCardDetailsModal] = useState(false);

  const [cardNumber, setCardNumber] = useState('');
  const [cvc, setCvc] = useState('');
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [Expiry, setExpiry] = useState('');
  const [Loading, setLoading] = useState(false);
  const [cardTypeImage, setCardTypeImage] = useState('');
  const [tokensBalance, settokensBalance] = useState(null);
  const address = useAddress();
  const chainId = useChainId();
  const inputRef = useRef(null);
  const handleCardNumberChange = (e) => {
    const inputValue = e.target.value.trim(); 
    if (!inputValue) {
      setCardNumber('');
      setCardTypeImage(null);
      return;
    }
    

    const sanitizedValue = e.target.value.replace(/[^0-9]/gi, '');
    let caretPosition = e.target.selectionStart;

    const parts = [];
    for (let i = 0, len = sanitizedValue.length; i < len; i += 4) {
      parts.push(sanitizedValue.substring(i, i + 4));
    }

    for (let i = caretPosition - 1; i >= 0; i--) {
      const c = e.target.value[i];
      if (c < '0' || c > '9') {
        caretPosition--;
      }
    }
    caretPosition += Math.floor(caretPosition / 4);

    setCardNumber(parts.join('-'));
    e.target.selectionStart = e.target.selectionEnd = caretPosition;
    const cardType = creditCardType(sanitizedValue)[0];
    if (cardType) {
      setCardTypeImage(cardTypeImages[cardType.type]);
    }
  };
  const handleCVVChange = (e) => {
    const input = e.target.value;
    const sanitizedInput = input.replace(/\D/g, ''); 
    setCvc(sanitizedInput);
};

  const handleExpirationDateChange = (e) => {
    const sanitizedValue = e.target.value.replace(/[^0-9]/gi, '');
    let caretPosition = e.target.selectionStart;

    const parts = [];
    for (let i = 0, len = sanitizedValue.length; i < len; i += 2) {
      parts.push(sanitizedValue.substring(i, i + 2));
    }

    for (let i = caretPosition - 1; i >= 0; i--) {
      const c = e.target.value[i];
      if (c < '0' || c > '9') {
        caretPosition--;
      }
    }
    caretPosition += Math.floor(caretPosition / 2);

    const month = parts[0] || ''; 
    const year = parts[1] || '';  

    setExpiryMonth(month);
    setExpiryYear(year);

    const formattedValue = parts.join('/');
    setExpiry(formattedValue);

    e.target.selectionStart = e.target.selectionEnd = caretPosition;
  };

  const handleCloseModal = () => {
    setShowCardDetailsModal(false);
    setCardNumber("");
    setCvc("");
    setExpiry("");
    setExpiryMonth("");
    setExpiryYear("");
    setCardTypeImage(null);
  };
  const contextData = useContextDetails();
  const balancelimit = contextData?.IsBalance
  
    const getValidatorsfunction = (address) => {
        if (!address || !chainId || chainId !== ChainIds) {
            return; 
        }
        getTokenBalance(tokenContractAddress,address)
          .then((fee) => {
            settokensBalance(fee  / 10 ** 18)
          })
          .catch(() => {
            // setAmount('');
          });        }
        useEffect(() => {
            if (!address || !chainId || chainId !== ChainIds) {
                setAmount('');
                settokensBalance('')
            }
            getValidatorsfunction(address);
          }, [address,chainId]);
          useEffect(()=>{
            if(!tokensBalance){
        setAmount('')
            }
          },[tokensBalance])
          useEffect(()=>{
            contextData?.setonlinebalace(tokensBalance);
        },[tokensBalance])
  const handlePaymentMethodClick = (method) => {
    setSelectedMethod(method);
    setAmount('');

    if (method === 'Online Coin') {
      if (!address) {
        showNotification('Please connect your wallet',NotificationTypes.ERROR);
                return; }
                
      setWalletAddress(masterwallect);
      setAmount('');
      setExchangeRate(null);

    } else {
      setWalletAddress('');
      setExchangeRate(null);
    }
  };

  const handleAmountChange = (event) => {
    let input = event.target.value?.trim();

    input = input.replace(/[^0-9.]/g, '');

    const [integerPart, decimalPart] = input.split('.');
    const isWithinLimit = !isNaN(input) && integerPart.length <= 9 && (!decimalPart || decimalPart.length <= 2);

    if (selectedMethod === 'Online Coin') {
      if (tokensBalance === 0) {
        // showNotification('Token balance is zero', NotificationTypes.ERROR);
        return;
      }
      const isWithinTokenBalance = parseFloat(input) <= tokensBalance;

      if (!isWithinTokenBalance && input !== '') {
        // showNotification('Entered amount exceeds token balance', NotificationTypes.ERROR);
        return;
      }
    } else if (selectedMethod === 'Offline Coin') {
      const isWithinOfflineBalanceLimit = parseFloat(input) <= balancelimit;

      if (!isWithinOfflineBalanceLimit && input !== '') {
        // showNotification('Entered amount exceeds offline balance limit', NotificationTypes.ERROR);
        return;
      }
    }

    if (isWithinLimit || input === '') {
      setAmount(input);
      setIsSwap(!!input.trim());
    } else {
      event.target.value = '';
    }
  };


  const handleEnterAmount = (e) => {
    e.preventDefault();
    if (selectedMethod === 'Online Coin' && !address) {
      showNotification('Please connect your wallet', NotificationTypes.ERROR);

      setIsLoading(false);
      return;
    }
    if (selectedMethod === 'Online Coin' &&  chainId!=ChainIds) {
      showNotification('Please switch network to the correct chain ',NotificationTypes.ERROR);
      setIsLoading(false);
      return;
  }
    if (selectedMethod === 'Online Coin' && !tokensBalance) {
      showNotification('Account balance insufficient', NotificationTypes.ERROR);

      setIsLoading(false);
      return;
    }

    if (!amount) {
      showNotification('Please fill in all required fields', NotificationTypes.ERROR);
      return;
    }
    if (parseFloat(amount) === 0) {
      showNotification('Amount cannot be zero', NotificationTypes.ERROR);
      return;
    }
    if (!exchangeRate && selectedMethod !== 'Bank Card') {
      showNotification('Exchange rate is not available. Please try again later.', NotificationTypes.ERROR);
      return;
    }
  
    if (selectedMethod === 'Bank Card' && isSwap) {
      // Open the card details modal
      setShowCardDetailsModal(true);
    } else {
      // Handle other payment methods or show an error message
      if (selectedMethod === 'Bank Card') {
        showNotification('Please check the conditions for Bank Card payment method', NotificationTypes.ERROR);
      }
      setIsLoading(true);

      if (selectedMethod === 'Offline Coin') {
        handleOfflineSwap();
      } else if (selectedMethod === 'Online Coin') {
        handleOnlineSwap();
      }
    }
  };
  let intervalId ;
  const updateBalance = () => {
      const delayedFunctionCall = () => {
          if (!address || !chainId || chainId !== ChainIds) {
              return;
          }
          getTokenBalance(tokenContractAddress, address)
              .then((fee) => {
                  let _balance = fee / 10 ** 18;
                  if (tokensBalance == _balance) {
                      return; 
                      
                  }
                  settokensBalance(_balance);
                 clearInterval(intervalId)
              })
              .catch(() => {
              });
      };
  
      const intervalTime = 2000;
      intervalId = setInterval(delayedFunctionCall, intervalTime);
  
      return intervalId;
  };

  const handleOfflineSwap = async () => {
    try {
      const requestData = {
        Name: "1cm",
        // UserCurrentRate: "0.2545",
        UserCurrentRate: exchangeRate,
        Amount: amount,
        Creator: contextData?.userInfo?.name,
        CreatorAddress: ""
      };

      await postGiftPurchase(requestData);

      showNotification('Gift successfully created And Buy', NotificationTypes.SUCCESS);
      setAmount('');
      updateBalance()
      setIsSwap(false);
      fetchData();
      getValidatorsfunction(address);
      setExchangeRate(null);
    } catch (error) {
      showNotification(error?.response?.data?.error?.errorMessage, NotificationTypes.ERROR);
    } finally {
      setIsLoading(false);
    }
  }
 
  
  const handleOnlineSwap = async () => {
    if (!address) {
      showNotification('Please connect your wallet', NotificationTypes.ERROR);

      setIsLoading(false);
      return;
    }
    if (!address || !tokensBalance) {
      showNotification('Account balance insufficient', NotificationTypes.ERROR);
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    try {

      const web3 = new Web3(window.ethereum);

      const tokenContract = new web3.eth.Contract(tokenContractAbi, tokenContractAddress);

      const amountInWei = web3.utils.toWei(amount, 'ether');
      const transationHash = await tokenContract.methods
        .transfer(walletAddress, amountInWei)
        .send({ from: address });
        setTxHash(transationHash?.transactionHash);
      sessionStorage.setItem("txHash", transationHash?.transactionHash);
      sessionStorage.setItem("offerredAmount", amount);
      sessionStorage.setItem("userAddress", address);
      sessionStorage.setItem("Creator", contextData?.userInfo?.name);
      sessionStorage.setItem("UserCurrentRate", exchangeRate);

      const requestData = {
        Name: "1cm",
        Amount: amount,
        Creator: contextData?.userInfo?.name,
        CreatorAddress: address,
        // UserCurrentRate :"1"
        UserCurrentRate: exchangeRate,
      };
      await new Promise(resolve => setTimeout(resolve, 3000));
      await postOnlineGiftPurchase(transationHash?.transactionHash, requestData);
      sessionStorage.removeItem("txHash");
      sessionStorage.removeItem("offerredAmount");
      sessionStorage.removeItem("userAddress");
      sessionStorage.removeItem("Creator");
      sessionStorage.removeItem("UserCurrentRate");
      showNotification('Gift successfully created And Buy', NotificationTypes.SUCCESS);
      setAmount('');
      setExchangeRate("")
      setIsSwap(false);
      fetchData();
      getValidatorsfunction(address);

    } catch (error) {
      showNotification('Transaction has been rejected', NotificationTypes.ERROR);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    (async () => {
        const storedTxHash = sessionStorage.getItem("txHash");
        const storedOfferedAmount = sessionStorage.getItem("offerredAmount");
        const storedUserAddress = sessionStorage.getItem("userAddress");
        const storedCreator = sessionStorage.getItem("Creator");
        const storedCurrent = sessionStorage.getItem("UserCurrentRate");



        if (storedTxHash && storedOfferedAmount && storedUserAddress) {
            try {
                setIsLoading(true);

                const requestData = {
                    // txHash: storedTxHash,
                    // offerredAmount: storedOfferedAmount,
                    // userAddress: storedUserAddress,
                
                Name: "1cm",
                Amount: storedOfferedAmount,
                Creator: storedCreator,
                CreatorAddress: storedUserAddress,
                // UserCurrentRate :"1"
                UserCurrentRate: storedCurrent,
                }
                await postOnlineGiftPurchase(storedTxHash, requestData);
                sessionStorage.removeItem("txHash");
                sessionStorage.removeItem("offerredAmount");
                sessionStorage.removeItem("userAddress");
                sessionStorage.removeItem("Creator");
                sessionStorage.removeItem("UserCurrentRate");

                showNotification('You got your amount in a while', NotificationTypes.SUCCESS);
                setAmount('');
                setIsSwap(false);
                setExchangeRate(null);
                fetchData();
                getValidatorsfunction(address);
            } catch (error) {
              sessionStorage.removeItem("txHash");
              sessionStorage.removeItem("offerredAmount");
              sessionStorage.removeItem("userAddress");
              sessionStorage.removeItem("Creator");
              sessionStorage.removeItem("UserCurrentRate");
                setAmount('');
            } finally {
                setIsLoading(false);
            }
        } else {
            // console.log('Required information not found in session storage');
        }
    })();
}, []);

  const fetchData = async () => {
    try {
      const balance = await fetchWalletBalance();
      const roundedBalance = Math.round(balance);
      contextData?.setIsBalance(roundedBalance);
    } catch (error) {
      console.error('Error fetching wallet balance:', error);
    }
  };

  useEffect(() => {

    fetchData();
  }, []);
  useEffect(() => {
    const fetchRate = async () => {
      if (amount) {
        try {
          const rate = await fetchExchangeRate(amount);
          setExchangeRate(rate);
        } catch (error) {
          console.error('Error fetching exchange rate:', error);
        }
      }
    };

    fetchRate();
  }, [amount]);
  const handleCardDetailsSubmitCCv = async (e) => {
    e.preventDefault();
    const currentYear =(new Date().getFullYear() % 100)
    if (expiryYear !== '' && parseInt(expiryYear) < currentYear &&expiryMonth !== '' && (parseInt(expiryMonth) < 1 || parseInt(expiryMonth) > 12)) {
      showNotification('Invalid Card Expiry Date', NotificationTypes.ERROR);
        return; 
    }
    if (expiryMonth !== '' && (parseInt(expiryMonth) < 1 || parseInt(expiryMonth) > 12)) {
      showNotification('Invalid Card Expiry Month', NotificationTypes.ERROR);
      return; 
  }
  
    if (expiryYear !== '' && parseInt(expiryYear) < currentYear) {
      showNotification('Invalid Card Expiry year ', NotificationTypes.ERROR);
        return; 
    }
    
    if (!cardNumber || !cvc || !expiryMonth || !expiryYear) {
      showNotification('Please fill in all required fields', NotificationTypes.ERROR);
      return;
    }
    if (cvc?.length < 3) {
      showNotification('CVV must be a 3-digit number', NotificationTypes.ERROR);
      return;
  }

    setLoading(true);
    try {
      const formattedCardNumber = cardNumber.replace(/-/g, '');
      const response1 = {
        grant_type: "client_credentials",
        client_id: clientId,
        client_secret: clientSecret,
      };
      const gettoken = await GetToken(response1)
      const tokenrespone =gettoken?.access_token

      const payloadData = {
        first_name: "",
        last_name: "",
        company: "",
        bcc_emails: null,
        telephone: "",
        address1: "",
        address2: "",
        city: "",
        province: null,
        country: null,
        postal_code: "",
        order_id: "",
        invoice_id: "",
        description: "",
        currency: "CAD",
        card_number: formattedCardNumber,
        cvv2: cvc,
        card_expiry_month: expiryMonth,
        card_expiry_year: expiryYear,
        amount: amount,
        // payment_token: paymentToken,
        // sending_receipt: true,
      };
      const pyamentrespone = await GetSaleToken(payloadData,tokenrespone)
      const payloadRequest = {
        Name: "1cm",
        Creator: contextData?.userInfo?.name,
        Description: "",
        Amount: pyamentrespone?.amount,
        AccessToken:tokenrespone,
        CardSuffix: pyamentrespone?.card_suffix,
        TransactionSuccess: pyamentrespone?.transaction_success,
        CardType: pyamentrespone?.card_type,
        Id: pyamentrespone?.id,
        TransactionId: pyamentrespone?.transaction_id,
        TransactionResult: pyamentrespone?.transaction_result,
        TransactionTime: "0",
        TransactionType: pyamentrespone?.transaction_type,
      };
      await GetBuyGiftsBankCard(payloadRequest)
      showNotification('Gift successfully created ', NotificationTypes.SUCCESS);

      setAmount("");
      setCardNumber("");
      setCvc("");
      setExpiry("");
      setExpiryMonth("");
      setExpiryYear("");
      setCardTypeImage(null);

    } catch (error) {
      // showNotification(error?.response?.data?.error, NotificationTypes.ERROR);
      if (error?.response && error?.response?.data && Array.isArray(error?.response?.data?.errors)) {
        const errorMessages = error?.response?.data?.errors?.map(errorItem => {
            const splitMessage = errorItem?.message.split('creditCard field :');
            if (splitMessage.length > 1) {
                return splitMessage[1]?.trim();
            } else if (errorItem?.message.includes("SQLException: Out of range value for column 'Amount' at row 1")) {
                return "The Amount Entered is out of Range";
            }
            return errorItem?.message;
        }).join('\n');
        showNotification(errorMessages, NotificationTypes.ERROR);
        
    } else if (error?.response && error?.response?.data && error?.response?.data?.message) {
        showNotification(error?.response?.data?.message, NotificationTypes.ERROR);
    } else {
        showNotification('An error occurred', NotificationTypes.ERROR);
    }
  
     setAmount("");
      setCardNumber("");
      setCvc("");
      setExpiry("");
      setExpiryMonth("");
      setExpiryYear("");
      setCardTypeImage(null);
    } finally {
      setShowCardDetailsModal(false);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (showCardDetailsModal) {
      inputRef.current.focus();
    }
  }, [showCardDetailsModal]);


  return (
    <>
      <div className='comming-soon-pnl'>
            <h1>Comming soon</h1>
          </div>
     {/*  <main className='inner-main'>
        <div className='full-div'>
        
       
          <div className='card-panel'>
          <Form>
            <h6>Purchase Gift Card</h6>

            <div className='spacer-10'></div>
            <h6>Select Payment Method</h6>

            <Link className={`payment-methode-pnl ${selectedMethod === 'Offline Coin' ? 'active' : ''}`}
              onClick={() => handlePaymentMethodClick('Offline Coin')}>
              <div className='img-pnl'>
                <img src={coin1} alt="coin" />
              </div>
              <div className='txt-pnl'>
                <h5>Offline Coin</h5>
                <p>Use your offline coins and buy.</p>
              </div>
            </Link>

            <Link to="#" className={`payment-methode-pnl ${selectedMethod === 'Online Coin' ? 'active' : ''}`}
              onClick={() => handlePaymentMethodClick('Online Coin')}>
              <div className='img-pnl'>
                <img src={coin1} alt="coin" />
              </div>
              <div className='txt-pnl'>
                <h5>Online Coin</h5>
                <p>Use your online coins and buy.</p>
              </div>
            </Link>

            <Link to="#" className={`payment-methode-pnl ${selectedMethod === 'Bank Card' ? 'active' : ''}`}
              onClick={() => handlePaymentMethodClick('Bank Card')}>
              <div className='img-pnl'>
                <img src={card} alt="coin" />
              </div>
              <div className='txt-pnl'>
                <h5>Bank Card</h5>
                <p>Use your MasterCard, MaestroPay or Visa Card and buy.</p>
              </div>
            </Link>
            <div className='your-amount-pnl'>
              
              <div className='amount-pnl'>
                <p>Enter Amount</p>
                <div className='flex-div'>
                  <h3>
                    <input
                      type='text'
                      value={amount}
                      onChange={handleAmountChange}
                      className='inputfiled'
                      placeholder='0'
                      autoComplete='off'
                      onPaste={(e) => e.preventDefault()}
                    />
                  </h3>
                </div>
                <div className='spacer-10'></div>
                <div className='text-right'>
                  <p><div className='small-img-pnl'>
                  
                    <span>
              {selectedMethod !== 'Bank Card' && <img src={coin1} alt="coin" />}
             {selectedMethod === 'Online Coin' ? formatAmount(tokensBalance) : selectedMethod === 'Bank Card' ? null : formatAmount(contextData?.IsBalance)}
         </span>

                  </div>


                  </p>
            
                   {['Online Coin', 'Offline Coin'].includes(selectedMethod) && amount && exchangeRate !== null && ( 
        <div>
        {exchangeRate && !isNaN(parseFloat(exchangeRate)) ? 
            `Exchange Rate: ${parseFloat(exchangeRate).toFixed(4)}` : 
            "" 
        }
    </div>
    
    )}
                </div>
        
              </div>
            </div>
          
            <Button  type='submit' className='grey-btn' onClick={handleEnterAmount} disabled={isLoading  }>
              {isLoading ? (
                <PulseLoader size={10} color="#fff" />
              ) : isSwap ? (
                'Continue'
              ) : (
                'Enter an amount'
              )}
            </Button>

            </Form>
          </div>
        
        </div>
      </main>
        */}
      <Modal centered show={showCardDetailsModal} onHide={handleCloseModal}>
        <Button className="close-btn" onClick={handleCloseModal}>
          <FontAwesomeIcon icon={faClose} />
        </Button>
        <Modal.Header id='modelCard'>
          <Modal.Title className='Model'>Card Details</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleCardDetailsSubmitCCv}>
        <div id='CardBox' className='card-panel'>
          {/* <h6>Card Details</h6> */}
          <Col xl="12" lg="12" sm="12" className='text-center'>
            <div className='border-div'></div>
          </Col>
          <Row>
            <Col xl="12" lg="12" sm="12">
           
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Card Number</Form.Label>
                  <div className='billing-address-pnl'>
                    <input
                     ref={inputRef}
                      type="text"
                      name="cardno"
                      value={cardNumber}
                      onChange={handleCardNumberChange}
                      placeholder="0000-0000-0000-0000"
                      minLength="19"
                      maxLength="19"
                      className='cardinput'
                    />
                    {cardTypeImage ? <img className='cardimg' src={cardTypeImage} alt="Card Type" /> : <FontAwesomeIcon icon={faCreditCard} />}


                  </div>
                </Form.Group>
          
            </Col>
            <Col xl="6" lg="6" sm="6" className='p-r10'>
           
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Expiry Date</Form.Label>
                  <input
                    type="text"
                    name="exp"
                    value={Expiry}
                    onChange={handleExpirationDateChange}
                    placeholder="MM/YY"
                    minLength="5"
                    className='cardinputExp'
                    maxLength="5"
                  />

                </Form.Group>
             
            </Col>
            <Col xl="6" lg="6" sm="6" className='p-l10'>
             
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>CVC</Form.Label>

                  <input
                    type="text"
                    name="cvcpwd"
                    value={cvc}
                    onChange={handleCVVChange}
                    placeholder="123"
                    minLength="3"
                    maxLength="3"
                    className='cardinputCCv'
                  />

                </Form.Group>
           
            </Col>
           
          </Row>
          <Button
            // onClick={handleCardDetailsSubmitCCv}
            type='submit'
            disabled={Loading}
            className='purple-btn' >
            {Loading ? <PulseLoader size={10} color="#fff" /> : 'Continue'}
          </Button>

        </div>
        </Form>
      </Modal>
    </>
  );
}
export default PurchaseGift; 