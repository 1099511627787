import React, { useState } from 'react';
import { Form, Button, Col, Row, Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
function CardDetails() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <main className='inner-main card-detail-pnl'>
                <div className='full-div'>
                    <div className='card-panel'>
                        <h6>Card Details</h6>
                        <Col xl="12" lg="12" sm="12" className='text-center'>
                            <div className='border-div'></div>
                        </Col>
                        <Row>
                            <Col xl="12" lg="12" sm="12">
                                <Form>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Card number</Form.Label>
                                        <div className='billing-address-pnl'>
                                            <p>2424 4242 2424 4242</p>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col xl="6" lg="6" sm="6" className='p-r10'>
                                <Form>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Expiry date</Form.Label>
                                        <Form.Control type="text" placeholder="MM/YYYY" />
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col xl="6" lg="6" sm="6" className='p-l10'>
                                <Form>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>CVC</Form.Label>
                                        <Form.Control type="text" placeholder="123" />
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col xl="12" lg="12" sm="12">
                                <Form>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Card billing address</Form.Label>
                                        <div className='billing-address-pnl'>
                                            <div>
                                                <p>12211</p>
                                                <p>Manhattan</p>
                                                <p>New York</p>
                                                <p>USA</p>
                                            </div>
                                            <div>
                                                <p>Update</p>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                        <Button className='purple-btn' onClick={handleShow}>Continue</Button>
                    </div>
                    {/* <div className='col-lg-12 btn-cntnr'>
                        <div className='spacer-40'></div>
                        <Button className='purple-btn'>Continue</Button>
                    </div> */}
                </div>
            </main>
            <Modal show={show} centered onHide={handleClose} animation={false}>
                <Modal.Header>
                    <Modal.Title>Purchase Gift Card?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>You can purchase gift card now or you can purchase it later.</p>
                    <div className='flex-div'>
                        <ul className='btn-list'>
                            <li>
                                <Button className="purple-btn" onClick={handleClose}>
                                    Purchase Now
                                </Button>
                            </li>
                            <li>
                                <Button className="send-btn" onClick={handleClose}>
                                    Purchase Later
                                </Button>
                            </li>
                        </ul>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default CardDetails; 