import React, { createContext, useState, useEffect, useContext } from "react";
import { instance } from "./Constant";

const AppContext = createContext(null);
export const useContextDetails = () => useContext(AppContext);
const AppProvider = (props) => {
  const [isLogged, setIsLogged] = useState(false);
  const [userInfo, setuserInfo] = useState({});
  const [userInfos, setuserInfos] = useState({});
  const [IsBalance, setIsBalance] = useState();
  const [metamaskAdd,setMetamaskAdd]=useState()
  const [isConnected,setisConnected] = useState(false)
  const [onlinebalace,setonlinebalace] =useState("")


  const [address, setaddress] = useState(false);


  const fetchUser = () => {
    const userDetailString = sessionStorage.getItem("userdetail");
    setuserInfo(JSON.parse(userDetailString))
  
   
  }
  useEffect(() => {
    fetchUser()

  }, [])
 

  useEffect(() => {
    const isUserLogin = localStorage.getItem("isUserLogin")

    if (isUserLogin) {
      setIsLogged(JSON.parse(isUserLogin))
    }

  }, [])
  useEffect(() => {
    const isaddress = localStorage.getItem("address")

    if (isaddress) {
      setaddress((isaddress))
    }

  }, [])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const isaddress = localStorage.getItem("address")
        if (isaddress) {
        const response = await instance.get("/api/Admin/GetWalletBalance");
        const parsedBalance = parseFloat(response?.data?.data);
        const roundedBalance = Math.round(parsedBalance);

        setIsBalance(roundedBalance);
        }
      } catch (error) {
        // Handle API errors here.
      }
    };

    fetchData();
  }, []);


  return (
    <AppContext.Provider
      value={{
        isLogged,
        setIsLogged,
        userInfo,
        setuserInfo,
        address,
        setaddress,
        IsBalance,
        setIsBalance,
        metamaskAdd,
        setMetamaskAdd,
        setisConnected,
        isConnected,
        setonlinebalace,
        onlinebalace,
        
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export { AppContext };

export { AppProvider };
